<template>
  <div>

    <InvoiceEditorModal 
      :item="invoice"
      ref="invoice-editor-modal"
    />

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="$emit('create')"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('COMPANY.ADD_MEMBERSHIP')}}</a
      >

    </div>

    <!-- Table -->
    <div class="table-container">
      <b-table
        id="member-table"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="member-table"
      >
        <template #cell(invoice_id)="row">
          <a href="#"
            v-if="row.item.invoice_id"
            @click.prevent="open_invoice(row.item.invoice_id)"
          >
            # {{ row.item.invoice_id }}
          </a>
          <div v-else>
            -
          </div>
 
        </template>

        <template #cell(is_paid)="row">
          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="!row.item.is_paid"
            color="red"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.NOT_PAID') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-else
            color="green"
            outlined
          >
            {{ $t('INVOICE.PAYMENT_STATUSES.PAID_IN_FULL') }}
          </v-chip>

        </template>


      <template #cell(action)="data">
        <div class="text-right" v-if="!view_only">
          <a
            v-if="data.item.invoice_id"
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="download_invoice(data.item.invoice_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
            </span>
          </a>

          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="edit_row_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_row_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" v-if="items.length === 0">{{$t('MEMBERSHIP.NO_MEMBERSHIPS_FOUND')}}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import InvoiceEditorModal from '@/view/pages/ml/invoices/InvoiceEditorModal.vue';

export default {

  name: 'MemberCompanyTable',

  props: ['member','items','company','creating_member','view_only'],
  emits: ['select', 'delete', 'create'],

  mixins: [ toasts ],

  components: {
    InvoiceEditorModal
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {
    async open_invoice(invoice_id) {
      const res = await axios.get(`/invoice/${invoice_id}`);

      if (res.status === 200) {

        this.invoice = res.data;

        this.$refs['invoice-editor-modal'].show();
      }
    },

    async edit_row_clicked(item) {

      this.$nextTick(()=>{
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(item) {
      this.$emit('delete', item);
    },

    async download_invoice(invoice_id) {

      try {
        const res = await axios.get(`/invoice/file/${invoice_id}`);

        if (res.status === 200) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          return;
        }
      }
      catch (err) {
        console.error('download_invoice', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));
      
    },


  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      invoice: null,

      fields: [
        {
          key: 'company.name',
          label: this.$t('COMPANY.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'reg_at',
          label: this.$t('MEMBERSHIP.REG_AT'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'vtdt',
          label: this.$t('MEMBERSHIP.VTDT'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'period',
          label: this.$t('MEMBERSHIP.PERIOD'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.period.name;
          }
        },
        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
        {
          key: 'invoice_id',
          label: this.$t('MEMBERSHIP.INVOICE_ID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
